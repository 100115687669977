@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.App {
  text-align: center;
}
nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	margin-top: 2%;


}



nav a{
	text-decoration: none;
	color: rgb(238, 52, 52);
}

nav ul {
	list-style: none;
	display: flex; 

	width: 45%;
	justify-content: space-around;
	text-align: center;
}

nav ul a {
	text-decoration: none;

	font-weight: 700;
}


.about-me-header {
	font-family: 'Permanent Marker', cursive;
	color: rgb(238, 52, 52);
	font-size: 3.4vw;
}

.about-bio {
	font-family: 'Robot', sans-serif;

}

