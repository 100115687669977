@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

.App {
  text-align: center;
}
nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	margin-top: 2%;


}



nav a{
	text-decoration: none;
	color: rgb(238, 52, 52);
}

nav ul {
	list-style: none;
	display: flex; 

	width: 45%;
	justify-content: space-around;
	text-align: center;
}

nav ul a {
	text-decoration: none;

	font-weight: 700;
}


.about-me-header {
	font-family: 'Permanent Marker', cursive;
	color: rgb(238, 52, 52);
	font-size: 3.4vw;
}

.about-bio {
	font-family: 'Robot', sans-serif;

}
